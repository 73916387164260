<template>
  <div>
    <v-app-bar
      app
      clipped-right
      class="ma-6 mr-0 mt-0 green darken-4 header-main green-gradient"
      height="80"
    >
      <v-btn
        elevation="0"
        width="40"
        min-width="40"
        height="40"
        class="pa-0 mr-4"
        @click="
          $vuetify.breakpoint.smAndDown
            ? setDrawer(!drawer)
            : $emit('input', !value)
        "
      >
        <v-icon color="primary" size="28">mdi-menu</v-icon>
      </v-btn>
      <v-btn
        v-for="item in menuItems"
        :key="item.title"
        :to="item.path"
        color="primary"
        elevation="0"
      >
        {{ item.title }}
      </v-btn>
      <v-spacer />
      <!---right part -->
      <v-select
        :menu-props="{ top: false, offsetY: true }"
        v-model="lang"
        :item-value="(item) => item.lang"
        :items="items"
        @change="changeLanguage(lang)"
        solo
        class="custom-select language-select mt-6"
      >
        <template v-slot:selection="{ item }">
          <img :src="item.image" width="34" class="" />
        </template>
        <template v-slot:item="{ item }">
          <img :src="item.image" width="25" class="mr-3 mt-2 mb-2 pa-1" /><span
            >{{ item.name }}</span
          >
        </template>
        <template v-slot:item="{ item }">
          <img class="ma-2 ml-0" width="25" :src="item.image" />{{ item.name }}
        </template>
      </v-select>
      <v-btn
        width="50"
        class="mr-2 log-out ml-4"
        color="green darken-4"
        @click="openChat = true"
        v-if="!currentRouteName.includes('Login')"
      >
        <v-icon size="28" medium>mdi-chat</v-icon>
        <v-badge
          color="red"
          class="mb-6"
          :content="badgeCount"
          v-if="badgeCount !== 0"
        />
      </v-btn>
      <v-btn
        width="50"
        class="ml-2 log-out"
        color="green darken-4"
        @click="doLogout"
      >
        <v-icon size="25" medium>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <div>
      <chat
        :show="openChat"
        @countUnseen="getUnseen"
        @updateShow="openChat = false"
        v-if="!currentRouteName.includes('Login')"
      />
    </div>
  </div>
</template>
<script>
import { loadLanguageAsync } from "@/plugins/i18n";
import { mapGetters, mapMutations, mapActions } from "vuex";
import chat from "../chat/Chat";
export default {
  name: "Header",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    chat,
  },
  data: () => ({
    badgeCount: 0,
    openChat: false,
    lang: localStorage.getItem("lang"),
    items: [
      {
        name: "English",
        lang: "en",
        image:
          "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
      },
      {
        name: "Spanish",
        lang: "es",
        image:
          "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/128-spain.svg",
      },
    ],
    sideNav: false,
  }),

  computed: {
    ...mapGetters({
      drawer: "drawer",
      menuItems: "common/getHeaderMenuItems",
    }),
    currentRouteName() {
      return this.$route.path;
    },
  },

  methods: {
    ...mapActions({
      logout: "user/logout",
    }),
    ...mapMutations({
      setDrawer: "setDrawer",
    }),
    async doLogout() {
      this.$store.commit("setLoader", true);
      try {
        await this.logout();
        this.$router.push("/");
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async changeLanguage() {
      await loadLanguageAsync(this.lang);
      localStorage.setItem("lang", this.lang);
    },

    getUnseen(data) {
      if (data != 0 && data != this.badgeCount) {
        const sound = require("../../assets/chattone.mp3");
        new Audio(sound).play();
      }
      this.badgeCount = data;
    },
  },
};
</script>
<style lang="scss">
.header-main {
  border-radius: 0 0 20px 20px !important;
}
div#list-11 {
  padding: 0;
}
/*.v-text-field__details {
  display: none;
}*/
.v-input__slot {
  margin-bottom: 0;
}
html .log-out {
  min-width: 40px !important;
  width: 40px !important;
  border-radius: 5px;
  background: rgb(255 255 255 / 15%) !important;
  color: #fff !important;
  box-shadow: none !important;
  height: 40px !important;

  &:before {
    opacity: 0 !important;
  }

  i {
    font-size: 20px;
  }
}
.custom-select {
  max-width: 150px;

  .v-select__selection--comma {
    font-size: 14px;
    color: #000;
  }

  .v-icon.v-icon {
    color: #000 !important;
  }
}
.language-select {
  max-width: 40px !important;

  .v-input__slot {
    margin-bottom: 0;
    padding: 0 !important;
    border-radius: 5px;
    box-shadow: none !important;
  }

  .v-select__selections {
    border-radius: 5px !important;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .v-input__append-inner {
    display: none !important;
  }

  /*  .v-text-field__details {
    display: none;
  }*/
}

.language-select .v-select__selections input {
  display: none;
}
.language-select.theme--light.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background: transparent;
  .v-select__slot {
    height: 40px !important;
    width: 40px !important;
  }
}
</style>
